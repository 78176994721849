
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import Table from "@/components/Table.vue";
import moment from "moment";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
  name: "index",
  components: {
    Table,
  },
  async mounted() {
    this.init();
  },
  methods: {
    init() {
      setCurrentPageBreadcrumbs("FIRST", ["Customers","CBS"]);
    },
  },
  data() {

    const store = useStore();
    const route = useRoute();
    // const pckg = route.params.pckg ?? null;
    const url = process.env.VUE_APP_API_URL + "/users/cbs/first"; // ✅
    const { n, t } = useI18n();

    return {
      // pckg,
      store:useStore(),
      ajax: url,
      order: [[0, "asc"]],
      columns: [
        {
          data: "id",
          title: "No",
          defaultContent: "-",
          className: "text-center",
          render: function (data, type, row, meta) {
            return meta.row + meta.settings._iDisplayStart + 1;
          },
        },
        {
          data: "name",
          defaultContent: "-",
          title: "Name",
        },
        {
          data: "nric",
          title: "IC No",
          defaultContent: "-",
          className: "text-start",
        },
        {
          data: "email",
          title: "Email",
          defaultContent: "-",
          className: "text-start",
        },
        {
          data: "phone",
          title: "Phone No",
          defaultContent: "-",
          className: "text-start",
        },
        {
          data: "dmp_ref",
          title: "DMP reference No",
          defaultContent: "-",
          className: "text-start",
        },
        // {
        //   data: "nric",
        //   title: "IC No",
        //   defaultContent: "-",
        //   className: "text-center",
        //   render: function (data, type, row, meta) {
        //     return data ? t(`organisation.table.${data}`) : "-";
        //   },
        // },
        // {
        //   data: "latitude",
        //   title: t("organisation.table.latitude"),
        //   defaultContent: "-",
        //   className: "text-center",
        // },
        // {
        //   data: "longitude",
        //   defaultContent: "-",
        //   title: t("organisation.table.longitude"),
        //   className: "text-center",
        // },
        // {
        //   data: "created_at",
        //   defaultContent: "-",
        //   title: t("organisation.table.created_at"),
        //   className: "text-center",
        //   render: function (data, type, row, meta) {
        //     return data ? moment(data).fromNow() : "-";
        //   },
        // },
        // {
        //   data: "updated_at",
        //   defaultContent: "-",
        //   title: t("organisation.table.updated_at"),
        //   className: "text-center",
        //   render: function (data, type, row, meta) {
        //     return data ? moment(data).fromNow() : "-";
        //   },
        // },
        {
          data: "action",
          title: t("organisation.table.action"),
        },
      ],
    };
  },
  setup(props) {
    const { t } = useI18n();

    return { t };
  },
});
